import { customFont, sizeConfig } from "../constants";
import { Shapes } from "../interfaces/shapes.interface";
import { Bear } from "./bear";

// TODO: Should be sprite
export const mountainsTextureName = "mountains";
export const villageTextureName = "village";

export const statueTextureName = "statue";
export const customTextureName = "custom";
export const marketplaceTextureName = "marketplace";
export const stakingTextureName = "staking";
export const aboutTextureName = "about";
export const casinoTextureName = "casino";
export const centralBuildinTextureName = "central-building";
export const hotelTextureName = "hotel";

export class Buildings {
  scene: Phaser.Scene;
  bear: Bear;
  buildingsGroup: Phaser.Physics.Matter.Image[];
  buildingNames: Phaser.GameObjects.Text[];
  buildingInCollisionWithBear: Phaser.Physics.Matter.Image;

  constructor(aParams: {
    scene: Phaser.Scene;
    bear: Bear;
    shapes: Shapes;
    villageShapes: Shapes;
  }) {
    this.scene = aParams.scene;
    this.bear = aParams.bear;

    this.buildingsGroup = [];
    this.buildingNames = [];

    this.initBuildings(aParams.shapes, aParams.villageShapes);
    this.attachClickEvents();
    this.attachCollisionEvents();
  }

  public handleCursors(keyCode: number) {
    if (keyCode === Phaser.Input.Keyboard.KeyCodes.H) {
      this.displayModalForBuild("about");
    }
    else if (keyCode === Phaser.Input.Keyboard.KeyCodes.ENTER && this.buildingInCollisionWithBear) {
      this.displayModalForBuild(
        (this.buildingInCollisionWithBear.body as any).label
      );
    }
  }

  public displayModalForBuild(label: string) {
    if (!this.scene.scene.isActive("modal")) {
      this.scene.scene.run("modal", { label });
    }
  }

  private initBuildings(shapes: Shapes, villageShapes: Shapes) {
    // Village
    const villageBuildingAndTrees = this.scene.matter.add
      .image(0, 0, villageTextureName)
      .setScale(sizeConfig.scale);
    villageBuildingAndTrees.setBody(villageShapes[villageTextureName]);
    villageBuildingAndTrees.setStatic(true);
    villageBuildingAndTrees.setPosition(
      sizeConfig.screenSize.width / 2 - 4,
      sizeConfig.screenSize.height / 2 + 67
    );
    villageBuildingAndTrees.setCollisionGroup(-1); // Same as the bees

    // Moutains
    const mountains = this.scene.matter.add
      .image(0, 0, mountainsTextureName)
      .setScale(sizeConfig.scale);
    mountains.setBody(shapes[mountainsTextureName]);
    mountains.setStatic(true);
    mountains.setPosition(
      sizeConfig.screenSize.width / 2,
      sizeConfig.screenSize.height / 2 - 50
    );
    mountains.setCollisionGroup(-1); // Same as the bees

    // Building - Statue
    const statue = this.scene.matter.add
      .sprite(949, 860, statueTextureName)
      .setScale(sizeConfig.scale);
    statue.setBody(shapes[statueTextureName]);
    statue.setStatic(true);
    this.scene.anims.create({
      key: "statue-anim",
      frames: this.scene.anims.generateFrameNumbers(statueTextureName, {
        start: 0,
        end: 6,
      }),
      frameRate: 10,
      repeat: -1,
    });
    statue.anims.play("statue-anim", true);
    this.buildingsGroup.push(statue);

    // Building - Custom
    const custom = this.scene.matter.add
      .sprite(716, 955, customTextureName)
      .setScale(sizeConfig.scale);
    custom.setBody(shapes[customTextureName]);
    custom.setStatic(true);
    this.scene.anims.create({
      key: "custom-anim",
      frames: this.scene.anims.generateFrameNumbers(customTextureName, {
        start: 0,
        end: 5,
      }),
      frameRate: 10,
      repeat: -1,
    });
    custom.anims.play("custom-anim", true);
    this.buildingsGroup.push(custom);

    // Building - Staking
    const staking = this.scene.matter.add
      .image(781, 399, stakingTextureName)
      .setScale(sizeConfig.scale);
    staking.setBody(shapes[stakingTextureName]);
    staking.setStatic(true);
    staking.setCollisionGroup(-1); // Same as the bees
    this.buildingsGroup.push(staking);

    // Building - Marketplace
    const marketplace = this.scene.matter.add
      .image(1243, 834, marketplaceTextureName)
      .setScale(sizeConfig.scale);
    marketplace.setBody(shapes[marketplaceTextureName]);
    marketplace.setStatic(true);
    this.buildingsGroup.push(marketplace);

    // Building - About
    const about = this.scene.matter.add
      .image(1420, 1005, aboutTextureName)
      .setScale(sizeConfig.scale);
    about.setBody(shapes[aboutTextureName]);
    about.setStatic(true);
    this.buildingsGroup.push(about);

    this.addBuildingsTexts();

    // Building - Casino
    const casino = this.scene.matter.add
    .sprite(1112, 816, casinoTextureName)
    .setScale(sizeConfig.scale);
    casino.setBody(shapes[casinoTextureName]);
    casino.setStatic(true);
    this.scene.anims.create({
      key: "casino-anim",
      frames: this.scene.anims.generateFrameNumbers(casinoTextureName, {
        start: 0,
        end: 7,
      }),
      frameRate: 10,
      repeat: -1,
    });
    casino.anims.play("casino-anim", true);
    this.buildingsGroup.push(casino);

    // Building - Central Building
    const centralBuilding = this.scene.matter.add
    .sprite(951, 385, centralBuildinTextureName)
    .setScale(sizeConfig.scale);
    centralBuilding.setBody(shapes[centralBuildinTextureName]);
    centralBuilding.setStatic(true);
    this.scene.anims.create({
      key: "central-building-anim",
      frames: this.scene.anims.generateFrameNumbers(centralBuildinTextureName, {
        start: 0,
        end: 7,
      }),
      frameRate: 10,
      repeat: -1,
    });
    centralBuilding.anims.play("central-building-anim", true);
    this.buildingsGroup.push(centralBuilding);

    // Building - Hotel
    const hotel = this.scene.matter.add
    .sprite(1233, 651, hotelTextureName)
    .setScale(sizeConfig.scale);
    hotel.body.gameObject.setCircle(1, 1); // No shape
    hotel.setStatic(true);
    this.scene.anims.create({
      key: "hotel-anim",
      frames: this.scene.anims.generateFrameNumbers(hotelTextureName, {
        start: 0,
        end: 7,
      }),
      frameRate: 5,
      repeat: -1,
    });
    hotel.anims.play("hotel-anim", true);
  }

  private attachClickEvents() {
    this.buildingsGroup.forEach((building) => {
      building.setInteractive();
      building.on("pointerdown", () => building.setTint(0x666666));
      building.on("pointerover", () => this.hoverBuilding(building, true));
      building.on("pointerout", () => this.hoverBuilding(building, false));
      building.on("pointerup", () => {
        this.hoverBuilding(building, false);
        this.displayModalForBuild((building.body as any).label);
      });
    });
  }

  private attachCollisionEvents() {
    const onCollision = (
      event: Phaser.Physics.Matter.Events.CollisionStartEvent,
      bodyA: MatterJS.BodyType,
      bodyB: MatterJS.BodyType
    ) => {
      const newBuildingInCollisionWithBear = this.getBuildingAndBearCollision(
        bodyA.gameObject,
        bodyB.gameObject
      );

      if (newBuildingInCollisionWithBear) {
        this.hoverBuilding(newBuildingInCollisionWithBear, true);
        this.buildingInCollisionWithBear = newBuildingInCollisionWithBear;
      }
    };
    this.scene.matter.world.on("collisionstart", onCollision.bind(this));
    this.scene.matter.world.on("collisionactive", onCollision.bind(this));
    this.scene.matter.world.on(
      "collisionend",
      (
        event: Phaser.Physics.Matter.Events.CollisionStartEvent,
        bodyA: MatterJS.BodyType,
        bodyB: MatterJS.BodyType
      ) => {
        const buildingInCollisionWithBear = this.getBuildingAndBearCollision(
          bodyA.gameObject,
          bodyB.gameObject
        );
        if (buildingInCollisionWithBear) {
          this.hoverBuilding(buildingInCollisionWithBear, false);
          this.buildingInCollisionWithBear = null;
        }
      }
    );
  }

  private getBuildingAndBearCollision(
    objectA: Phaser.Physics.Matter.Image,
    objectB: Phaser.Physics.Matter.Image
  ) {
    if (
      objectA === this.bear.body &&
      this.buildingsGroup.indexOf(objectB) >= 0
    ) {
      return objectB;
    } else if (
      objectB === this.bear.body &&
      this.buildingsGroup.indexOf(objectA) >= 0
    ) {
      return objectA;
    }
    return null;
  }

  private addBuildingsTexts() {
    // Load font
    WebFont.load({
      custom: {
        families: ["Pixellari"],
      },
      active: () => {
        this.buildingsGroup.forEach(building => {
          const label = (building.body as any).label;
          const buildingName = this.scene.add
            .text(
              building.getTopCenter().x,
              building.getTopCenter().y - 10,
              this.getBuildingText(label),
              {
              fontFamily: customFont,
              align: "center",
              fontSize: 20,
              color: "#eeeeee",
            })
            .setLineSpacing(10)
            .setOrigin(0.5, 1)
            .setVisible(false);
            this.buildingNames.push(buildingName);
        })
      },
    });
  }

  private getBuildingText(label: string) {
    switch (label) {
      case 'statue': return 'Roadmap';
      case 'about': return 'About';
      case 'marketplace': return 'Marketplace';
      case 'custom': return 'Inventory';
      case 'staking': return 'Staking';
      case 'central-building': return 'Website';
      case 'casino': return 'Shop';
    }
  }

  private hoverBuilding(building: Phaser.Physics.Matter.Image, isHover: boolean) {
      if (isHover) {
        building.setTint(0x999999);
      } else {
        building.clearTint();
      }

      const buildingIndex = this.buildingsGroup.indexOf(building);
      const buildingName = this.buildingNames[buildingIndex];
      buildingName.setVisible(isHover);
  }
}
