import { Bear, bearTextureName, gmTextureName, gnTextureName, lfgTextureName } from '../objects/bear';
import { Bee, beeTextureName } from '../objects/bee';
import {
  Buildings,
  statueTextureName,
  mountainsTextureName,
  villageTextureName,
  stakingTextureName,
  customTextureName,
  marketplaceTextureName,
  aboutTextureName,
  casinoTextureName,
  centralBuildinTextureName,
  hotelTextureName,
} from '../objects/buildings';
import { sizeConfig, customFont } from '../constants';
import { Shapes } from '../interfaces/shapes.interface';
import { BeesFactory } from '../objects/bees-factory';
import { modalAboutTextureName, modalPopupTextureName, modalRoadmapTextureName, noTextureName, okTextureName } from './modal';

// Import for dynamic caching
import backgroundImageUrl from '../../static/images/background.png';
import mountainsImageUrl from '../../static/images/mountains.png';
import logoWoodImageUrl from '../../static/images/logo-wood.png';
import villageImageUrl from '../../static/images/village.png';
import statueImageUrl from '../../static/images/buildings/statue.png';
import stakingImageUrl from '../../static/images/buildings/staking.png';
import customImageUrl from '../../static/images/buildings/custom.png';
import marketplaceImageUrl from '../../static/images/buildings/marketplace.png';
import aboutImageUrl from '../../static/images/buildings/about.png';
import casinoImageUrl from '../../static/images/buildings/casino.png';
import centralBuildingImageUrl from '../../static/images/buildings/central-building.png';
import hotelImageUrl from '../../static/images/buildings/hotel.png';
import gmImageUrl from '../../static/images/gm.png';
import gnImageUrl from '../../static/images/gn.png';
import lfgImageUrl from '../../static/images/lfg.png';
import modalPopupImageUrl from '../../static/images/popup-background.png';
import modalRoadmapImageUrl from '../../static/images/roadmap.gif';
import modalAboutImageUrl from '../../static/images/about-background.png';
import okImageUrl from '../../static/images/ok.png';
import noImageUrl from '../../static/images/no.png';
import discordImageUrl from '../../static/images/social/discord.png';
import twitterImageUrl from '../../static/images/social/twitter.png';
import bearShapesJsonUrl from '../../static/shapes/bear.json';
import villageShapesJsonUrl from '../../static/shapes/village.json';
import bearImageUrl from '../../static/images/bear.png';
import beeImageUrl from '../../static/images/bee.png';
import muteImageUrl from '../../static/images/mute.png';
import unmuteImageUrl from '../../static/images/unmute.png';
import audioThemeMp3Url from '../../static/audio/theme.mp3';
import audioThemeOggUrl from '../../static/audio/theme.ogg';

// Add local texture names
const backgroundTextureName = 'background';
const logoWoodTextureName = 'logo-wood';
const discordTextureName = 'discord';
const twitterTextureName = 'twitter';
const muteTextureName = 'mute';
const unmuteTextureName = 'unmute';
const audioThemeName = 'audio-theme';

export class MainScene extends Phaser.Scene {
  public cursors: Phaser.Types.Input.Keyboard.CursorKeys;
  public shapes: Shapes;
  public villageShapes: Shapes;

  private myBear: Bear;
  private buildings: Buildings;
  private beesFactory: BeesFactory;
  private logo: Phaser.GameObjects.Image;
  private music: Phaser.Sound.BaseSound;

  constructor() {
    super({ key: 'MainScene' });
  }

  init(): void {
    //  Inject our CSS
    var element = document.createElement('style');
    document.head.appendChild(element);

    var sheet = element.sheet;
    var styles =
      '@font-face { font-family: "Pixellari"; src: url("fonts/Pixellari.ttf"); font-weight:400; font-style:normal; font-display:swap; }\n';
    sheet.insertRule(styles, 0);
  }

  preload(): void {
    this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');

    this.load.image(backgroundTextureName, backgroundImageUrl);
    this.load.image(logoWoodTextureName, logoWoodImageUrl);
    
    this.load.image(mountainsTextureName, mountainsImageUrl);
    this.load.image(villageTextureName, villageImageUrl);
    this.load.spritesheet(statueTextureName, statueImageUrl, { frameWidth: 114, frameHeight: 114 });
    this.load.image(stakingTextureName, stakingImageUrl);
    this.load.spritesheet(customTextureName, customImageUrl, { frameWidth: 90, frameHeight: 90 });
    this.load.image(marketplaceTextureName, marketplaceImageUrl);
    this.load.image(aboutTextureName, aboutImageUrl);
    this.load.spritesheet(casinoTextureName, casinoImageUrl, { frameWidth: 99, frameHeight: 90 });
    this.load.spritesheet(centralBuildinTextureName, centralBuildingImageUrl, { frameWidth: 234, frameHeight: 234 });
    this.load.spritesheet(hotelTextureName, hotelImageUrl, { frameWidth: 114, frameHeight: 114 });
    this.load.image(gmTextureName, gmImageUrl);
    this.load.image(gnTextureName, gnImageUrl);
    this.load.image(lfgTextureName, lfgImageUrl);
    this.load.image(modalPopupTextureName, modalPopupImageUrl);
    this.load.image(modalRoadmapTextureName, modalRoadmapImageUrl);
    this.load.image(modalAboutTextureName, modalAboutImageUrl);
    this.load.image(okTextureName, okImageUrl);
    this.load.image(noTextureName, noImageUrl);
    this.load.image(muteTextureName, muteImageUrl);
    this.load.image(unmuteTextureName, unmuteImageUrl);

    this.load.image(discordTextureName, discordImageUrl);
    this.load.image(twitterTextureName, twitterImageUrl);

    // Load body shapes from JSON file generated using PhysicsEditor
    this.load.json('shapes', bearShapesJsonUrl);
    this.load.json('village-shapes', villageShapesJsonUrl);

    // Load spritesheet (animation)
    this.load.spritesheet(bearTextureName, bearImageUrl, {
      frameWidth: 45,
      frameHeight: 45,
    });
    this.load.spritesheet(beeTextureName, beeImageUrl, {
      frameWidth: 32,
      frameHeight: 32,
    });

    //  Firefox doesn't support mp3 files, so use ogg
    this.load.audio(audioThemeName, [audioThemeMp3Url, audioThemeOggUrl]);
  }

  create(): void {
    this.shapes = this.cache.json.get('shapes');
    this.villageShapes = this.cache.json.get('village-shapes');

    this.cameras.main.setBounds(0, 0, sizeConfig.screenSize.width, sizeConfig.screenSize.height);
    this.matter.world.setBounds(0, 0, sizeConfig.screenSize.width, sizeConfig.screenSize.height);

    this.cursors = this.input.keyboard.createCursorKeys();

    this.add.image(0, 0, backgroundTextureName).setOrigin(0).setScale(sizeConfig.scale);

    this.myBear = new Bear({
      scene: this,
      x: sizeConfig.screenSize.width / 2 - 8,
      y: sizeConfig.screenSize.height - 40,
      shape: this.shapes[bearTextureName],
    });
    this.buildings = new Buildings({
      scene: this,
      bear: this.myBear,
      shapes: this.shapes,
      villageShapes: this.villageShapes,
    });

    this.cameras.main.startFollow(this.myBear.body, true, 0.09, 0.09);
    this.cameras.main.roundPixels = true;

    // Bees
    this.beesFactory = new BeesFactory(this);
    this.beesFactory.startCreatingBees();

    this.input.keyboard.on('keydown-ENTER', () => {
      this.buildings.handleCursors(Phaser.Input.Keyboard.KeyCodes.ENTER);
    });
    this.input.keyboard.on('keydown-H', () => {
      this.buildings.handleCursors(Phaser.Input.Keyboard.KeyCodes.H);
    });
    this.input.keyboard.on('keydown', (e) => {
      if (e.key === '?') {
        this.buildings.handleCursors(Phaser.Input.Keyboard.KeyCodes.H);
      }
    });
    this.input.keyboard.on('keydown-M', () => {
      this.myBear.handleCursors(Phaser.Input.Keyboard.KeyCodes.M);
    });
    this.input.keyboard.on('keydown-L', () => {
      this.myBear.handleCursors(Phaser.Input.Keyboard.KeyCodes.L);
    });
    this.input.keyboard.on('keydown-N', () => {
      this.myBear.handleCursors(Phaser.Input.Keyboard.KeyCodes.N);
    });
    
    // Logo
    this.logo = this.add.image(50, 80, logoWoodTextureName).setOrigin(0).setScale(sizeConfig.scale);

    this.addStaticTexts();
    this.addStaticSocialIcons();

    // Display modal
    this.scene.run("modal", { label: "about" });

    // Start audio
    this.music = this.sound.add(audioThemeName, { volume: 0.5, loop: true });
    this.music.play();

    this.addMusicButtons();
  }

  update() {
    this.myBear.handleBasicCursors(this.cursors);
  }

  private addStaticTexts() {
    // Load font
    WebFont.load({
      custom: {
        families: ["Pixellari"],
      },
      active: () => {
        
        // Copyright
        this.add
          .text(
            40,
            sizeConfig.screenSize.height - 30,
            `© Copyright ${new Date().getFullYear()}, LazyBearZ`,
            {
            fontFamily: customFont,
            align: "center",
            size: 14,
            color: "#dddddd",
          })
          .setLineSpacing(10)
          .setOrigin(0, 0);
      },
    });
  }

  private addStaticSocialIcons() {
    const logoBottom = this.logo.getBottomCenter();

    const twitter = this.add.image(logoBottom.x - 10, logoBottom.y + 10, twitterTextureName).setOrigin(1, 0).setScale(sizeConfig.scale / 2);
    this.addOnClickEvent(twitter, () => window.open('https://twitter.com/lazybearznft', "_blank"));

    const discord = this.add.image(logoBottom.x + 10, logoBottom.y + 10, discordTextureName).setOrigin(0).setScale(sizeConfig.scale / 2);
    this.addOnClickEvent(discord, () => window.open('https://discord.com/invite/4sXrHQMsyH', "_blank"));
  }

  private addOnClickEvent(image: Phaser.GameObjects.Image, callback: Function) {
    image.setInteractive();
    image.on("pointerdown", () => image.setTint(0x666666));
    image.on("pointerover", () => image.setTint(0x999999));
    image.on("pointerout", () => image.clearTint());
    image.on("pointerup", () => {
      image.clearTint();
      callback();
    });
  }

  private addMusicButtons() {
    const muteOrUnmute = this.add.image(sizeConfig.screenSize.width - 30, sizeConfig.screenSize.height - 60, muteTextureName).setOrigin(1, 0).setScale(sizeConfig.scale / 2);
    this.addOnClickEvent(muteOrUnmute, () => {
      if (!this.music.isPlaying) {
        this.music.play();
        muteOrUnmute.setTexture(muteTextureName);
      } else {
        this.music.pause();
        muteOrUnmute.setTexture(unmuteTextureName);
      }
    });
  }
}
