import { Bee } from "./bee";

export class BeesFactory {
    private scene: Phaser.Scene;

    constructor(scene: Phaser.Scene) {
        this.scene = scene;
    }

    startCreatingBees() {
        // Create a first bee
        this.createBee();

        // Then create another each regularly
        this.scene.time.addEvent({
            delay: 1500,
            callback: () => {
                this.createBee();
            },
            callbackScope: this.scene,
            loop: true,
        });
    }

    private createBee() {
      const bee = new Bee({
        scene: this.scene,
        x: 780,
        y: 380,
      });

      // Bee is deleted after some time
      this.scene.time.addEvent({
        delay: 4000,
        callback: () => this.destroyElement(bee.body),
      });
    }
  
    private destroyElement(element: Phaser.Physics.Matter.Sprite) {
      element.destroy();
    }
}