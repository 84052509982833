import { sizeConfig } from '../constants';

export const bearTextureName = 'bear';
export const gmTextureName = 'gm';
export const gnTextureName = 'gn';
export const lfgTextureName = 'lfg';
export const bearVelocity = 2;

export class Bear {
  scene: Phaser.Scene;
  body: Phaser.Physics.Matter.Sprite;
  bubble: Phaser.Physics.Matter.Image;

  constructor(aParams: { scene: Phaser.Scene; x: number; y: number; shape: string }) {
    this.scene = aParams.scene;

    this.body = this.scene.matter.add.sprite(aParams.x, aParams.y, bearTextureName);
    this.body.setBody(aParams.shape);

    this.initSprite();
    this.initPhysics();

    // GM is created after some time
    this.scene.time.addEvent({
      delay: 500,
      callback: () => this.initBubble(gmTextureName),
    });
  }

  public handleBasicCursors(cursors: Phaser.Types.Input.Keyboard.CursorKeys) {
    this.body.setVelocity(0);

    let isMoving = false;
    if (cursors.left.isDown) {
      this.body.setVelocityX(-bearVelocity);
      this.body.anims.play('left', true);
      isMoving = true;
    } else if (cursors.right.isDown) {
      this.body.setVelocityX(bearVelocity);
      this.body.anims.play('right', true);
      isMoving = true;
    } else if (cursors.up.isDown) {
      this.body.setVelocityY(-bearVelocity);
      this.body.anims.play('up', true);
      isMoving = true;
    } else if (cursors.down.isDown) {
      this.body.setVelocityY(bearVelocity);
      this.body.anims.play('down', true);
      isMoving = true;
    } else {
      this.body.anims.play('idle', true);
    }

    if (isMoving) {
      this.destroyBubble();
    }
  }

  public handleCursors(keyCode: number) {
    if (keyCode === Phaser.Input.Keyboard.KeyCodes.M) {
      this.initBubble(gmTextureName);
    }
    else if (keyCode === Phaser.Input.Keyboard.KeyCodes.L) {
      this.initBubble(lfgTextureName);
    }
    else if (keyCode === Phaser.Input.Keyboard.KeyCodes.N) {
      this.initBubble(gnTextureName);
    }
  }

  private initSprite() {
    this.body.setScale(sizeConfig.scale);

    this.scene.anims.create({
      key: 'idle',
      frames: [{ key: bearTextureName, frame: 0 }],
      frameRate: 20,
    });

    this.scene.anims.create({
      key: 'down',
      frames: this.scene.anims.generateFrameNumbers(bearTextureName, {
        start: 0,
        end: 3,
      }),
      frameRate: 10,
      repeat: -1,
    });

    this.scene.anims.create({
      key: 'left',
      frames: this.scene.anims.generateFrameNumbers(bearTextureName, {
        start: 4,
        end: 7,
      }),
      frameRate: 10,
      repeat: -1,
    });

    this.scene.anims.create({
      key: 'right',
      frames: this.scene.anims.generateFrameNumbers(bearTextureName, {
        start: 8,
        end: 11,
      }),
      frameRate: 10,
      repeat: -1,
    });

    this.scene.anims.create({
      key: 'up',
      frames: this.scene.anims.generateFrameNumbers(bearTextureName, {
        start: 12,
        end: 15,
      }),
      frameRate: 10,
      repeat: -1,
    });
  }

  private initPhysics() {
    this.body.setFriction(0);
    this.body.setFixedRotation();
    this.body.setCollisionGroup(2);
  }

  private initBubble(textureName: string) {
    if (this.bubble) {
      return;
    }

    // Bubble
    this.bubble = this.scene.matter.add.image(0, 0, textureName).setScale(0.5);
    this.bubble.setCircle(1);
    this.bubble.setStatic(true);
    this.bubble.setPosition(this.body.x + 22, this.body.y - 30);
    this.bubble.setCollisionGroup(2); // Same as the bear

    // Bubble is deleted after some time
    this.scene.time.addEvent({
      delay: 3000,
      callback: () => this.destroyBubble(),
    });
  }

  private destroyBubble() {
    this.bubble?.destroy();
    this.bubble = null;
  }
}
