export const beeTextureName = "bee";

export class Bee {
  scene: Phaser.Scene;
  body: Phaser.Physics.Matter.Sprite;

  constructor(aParams: { scene: Phaser.Scene; x: number; y: number }) {
    this.scene = aParams.scene;

    this.body = this.scene.matter.add.sprite(
      aParams.x,
      aParams.y,
      beeTextureName
    );

    this.initSprite();
    this.initPhysics();
  }

  private initSprite() {
    this.body.setScale(0.5);

    this.scene.anims.create({
      key: "flying",
      frames: this.scene.anims.generateFrameNumbers(beeTextureName, {
        start: 0,
        end: 7,
      }),
      frameRate: 10,
      repeat: -1,
    });
    this.body.anims.play("flying", true);
  }

  private initPhysics() {
    this.body.setFrictionAir(0);
    this.body.setCollisionGroup(-1);
    this.body.setVelocity(
      Phaser.Math.Between(-500, -100)/500,
      Phaser.Math.Between(-500, -100)/500
    );
  }
}
