import { MainScene } from './scenes/main-scene';
import { Modal } from './scenes/modal';

export const GameConfig: Phaser.Types.Core.GameConfig = {
  title: 'LazyBearZ',
  url: 'https://lazybearz.io/',
  version: '1.0',
  scale: {
    mode: Phaser.Scale.FIT,
    parent: 'game',
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: 1919,
    height: 1080,
    autoRound: true,
  },
  backgroundColor: 0x3a404d,
  type: Phaser.AUTO,
  pixelArt: false,
  roundPixels: true,
  antialias: true,
  zoom: 1,
  physics: {
    default: 'matter',
    matter: {
      debug: false, //process.env.NODE_ENV !== 'production',
      gravity: { x: 0, y: 0 },
    },
  },
  scene: [MainScene, Modal],
};
