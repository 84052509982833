import { customFont, sizeConfig } from "../constants";

export const modalPopupTextureName = "popup-background";
export const modalRoadmapTextureName = "roadmap";
export const modalAboutTextureName = "about-background";
export const okTextureName = "yes";
export const noTextureName = "no";

export enum ModalType {
  Statue = "statue",
  Staking = "staking",
  Custom = "custom",
  Marketplace = "marketplace",
  About = "about",
  Website = "central-building",
  Shop = "casino",
} 

type Position = { x: number; y: number; width: number; height: number; }

export class Modal extends Phaser.Scene {
  constructor() {
    super("modal");
  }

  create(config: { label: ModalType }) {
    let modalType = config.label;
    const position = this.getPosition(modalType);
    if (!position) {
      this.close();
      return;
    }
    
    // Specific
    if (modalType === ModalType.Statue) {
      this.add.image(position.x, position.y, modalRoadmapTextureName).setOrigin(0, 0);
    }
    // About / Help
    else if (modalType === ModalType.About) {
      this.createModalAbout(position);
    }
    // Default case
    else {
      this.createModalWithBackgroundAndText(position, modalType);
    }

    this.input.keyboard.once("keydown-ESC", this.close, this);
    this.input.keyboard.once(
      "keydown-ENTER",
      () => this.openPage(modalType),
      this
    );
    this.input.once("pointerdown", this.close, this);
  }

  close() {
    this.scene.stop();
  }

  openPage(label: ModalType) {
    if (label === ModalType.Staking) {
      window.location.href = "https://dapp.lazybearz.io/staking";
    } else if (label === ModalType.Custom) {
      window.location.href = "https://dapp.lazybearz.io/inventory";
    } else if (label === ModalType.Marketplace) {
      window.location.href = "https://dapp.lazybearz.io/marketplace";
    } else if (label === ModalType.Shop) {
      window.location.href = "https://shop.lazybearz.io/";
    } else if (label === ModalType.Website) {
      window.location.href = "https://lazybearz.io/";
    }
  }

  private createModalAbout(position: Position) {
    this.add
      .image(position.x, position.y - 20, modalAboutTextureName)
      .setOrigin(0, 0);

    WebFont.load({
      custom: {
        families: ["Pixellari"],
      },
      active: () => {
        const textCenterPosition = position.x + position.width / 2;
        this.addText(
          textCenterPosition,
          position.y + 320,
          "A fully customizable NFT\nwith an evolving rarity.\nAirdrop, Breeding, Token,\nmany features will allow\nyou to make your NFT unique.",
          24, "#3f3532");
        
        // Last text
        this.addText(
          textCenterPosition,
          position.y + 500,
          "The Lazy Village is interactive!\nMove your BearZ around (←, →, ↑, ↓, M/N/L),\nand open some buildings (↵ Enter)\nto stake, market or customize.,\n-- Use F11 to open in fullscreen --",
          20,
          "#c7dcd0"
        );
      }
      });
  }

  private createModalWithBackgroundAndText(position: Position, label: ModalType) {
    this.createBackground(position);

    WebFont.load({
      custom: {
        families: ["Pixellari"],
      },
      active: () => {
        const textCenterPosition = position.x + position.width / 2 - 5;
        const textTopPosition = position.y + 50;

        // Title
        this.addText(
          textCenterPosition,
          textTopPosition,
          this.getMainText(label)
        );

        const yes = this.add.image(textCenterPosition - 20, textTopPosition + 50, okTextureName).setOrigin(1, 0).setScale(sizeConfig.scale / 2);
        this.addOnClickEvent(yes, () => this.openPage(label));

        const no = this.add.image(textCenterPosition + 20, textTopPosition + 50, noTextureName).setOrigin(0).setScale(sizeConfig.scale / 2);
        this.addOnClickEvent(no, () => this.close());

        // Last text
        this.addText(
          textCenterPosition,
          textTopPosition + 105,
          "(↵ Enter)    (Esc)    ",
          16,
          "#dddddd"
        );
      },
    });
  }

  private createBackground(position: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) {
    this.add.image(position.x, position.y, modalPopupTextureName).setOrigin(0, 0);
  }

  private addText(
    x: number,
    y: number,
    text: string,
    fontSize = 20,
    color = "#ffffff"
  ) {
    return this.add
      .text(x, y, text, {
        fontFamily: customFont,
        align: "center",
        fontSize,
        color,
      })
      .setLineSpacing(10)
      .setOrigin(0.5);
  }

  private getPosition(label: ModalType): Position {
    let x = 100;
    let y = 300;
    let width = 320;
    let height = 200;

    if (label === ModalType.Custom) {
      x = 550;
      y = 700;
    } else if (label === ModalType.Staking) {
      x = 620;
      y = 150;
    } else if (label === ModalType.Marketplace) {
      x = 1100;
      y = 540;
    } else if (label === ModalType.Website) {
      x = 800;
      y = 10;
    } else if (label === ModalType.Shop) {
      x = 960;
      y = 540;
    } else if (label === ModalType.Statue) {
      x = 595;
      y = 100;
      width = 520;
      height = 550;
    } else if (label === ModalType.About) {
      x = 667;
      y = 150;
      width = 561;
      height = 677;
    } else {
      return null;
    }

    return {
      x,
      y,
      width,
      height,
    };
  }

  private getMainText(label: ModalType) {
    if (label === ModalType.Custom) {
      return "Do you want to go to\nthe inventory?";
    } else if (label === ModalType.Staking) {
      return "Do you want to go to\nthe staking?";
    } else if (label === ModalType.Marketplace) {
      return "Do you want to go to\nthe marketplace?";
    } else if (label === ModalType.Shop) {
      return "Do you want to go to\nthe shop?";
    } else if (label === ModalType.Website) {
      return "Do you want to go to\nthe website?";
    }
    return "";
  }

  private addOnClickEvent(image: Phaser.GameObjects.Image, callback: any) {
    image.setInteractive();
    image.on("pointerover", () => image.setTint(0x999999));
    image.on("pointerout", () => image.clearTint());
    image.on("pointerdown", () => {
      image.clearTint();
      callback();
    });
  }
}
